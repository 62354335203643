import { ThirdwebProvider } from '@thirdweb-dev/react';
import '../styles/globals.css'
import '../styles/styles.scss'

import * as networks from "@thirdweb-dev/chains";

function MyApp({ Component, pageProps }) {
	return (
		<ThirdwebProvider
			activeChain={networks[process.env.NEXT_PUBLIC_TOKEN_CHAIN]}
			clientId={process.env.NEXT_PUBLIC_TEMPLATE_CLIENT_ID}
		>
			<Component {...pageProps} />
		</ThirdwebProvider>
	);
}

export default MyApp;
